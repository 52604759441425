.box {
    display: flex;
    flex-wrap: wrap;
    width: 48%;
    background-color:aquamarine;
}

@media only screen and (max-width: 900px) {
    .box {
        width: 100%;
    }
  }


.section-card-box:hover {
    background-color: #17B57A15;
    cursor: pointer;
    transition: .1s;
  
}

.selection-group {
    display: block;
   text-align: center;
}




    
.section-card-box {
    margin:10px;
    width: '100%';
    text-align: center;
    overflow-y: scroll;
    
    
    
} 

.employee-select-modal-box {
  width: '90%';  
}






.question-box{
    display:flex ;
    flex-direction:row ;
}

.question {
    flex:70% ;
    min-width:70% ;
}


.answer {
    flex:30% ;
    width:20px ;
    height:30px ;
    margin-right:10px ;
}



.above-btn{
        margin-top: 10px;
    }
@media (max-width: 750px) {
    .question-box{
        display:block !important;
     
    }
    
    .question {
       display: block !important;
       width: 100% !important;
    }
    
    .logo {
        width: 150px;
    }
    
    .answer {
       display: block !important;
       width: 100% !important;
    }

    .review-paper-container{
        padding: 10px !important;

    }
    .complete-heading{
        font-size: medium !important;
        font-weight: bold !important;
    }

    .complete-p{
        font-size: medium !important;
      
    }

    .above-btn{
        margin-top: 70px !important;
    }

    .demo-paper{
        margin-top: 150px !important;
    }

    .web-doc-paper{
        margin-top: 70px !important;
        width: 95%;
    }

  }